import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { PaperContainer } from '~views/shared'
import { EventForm } from '../components'
import { create } from 'state/modules/events'
import { loadOrganizers } from 'state/modules/organizers'
import { fetchEventTypes, fetchLocations } from 'state/modules/common'
import * as moment from 'moment'
import { PAYMENT_IDS, ACCOUNTS } from 'config/constants'
import { showSnackbarError } from 'views/utils/snackbar'
import { ConfirmationDialog } from '~/views/shared'

export const EventsCreatePage = ({ onSuccess, onCancel }) => {
  const dispatch = useDispatch()
  const { organizers, pending: pendingOrganizers } = useSelector(
    (state) => state.organizers
  )
  const { country } = useSelector((state) => state.app)
  const message =
    'La productora del evento NO tiene vinculada una cuenta de MercadoPago a ticketPass, el evento se rendirá a la cuenta de MercadoPago de ticketPass.'
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const { pending, actionPending } = useSelector((state) => state.events)
  const {
    locations,
    eventTypes,
    pending: pendingData
  } = useSelector((state) => state.common)

  useEffect(() => {
    async function fetchData() {
      dispatch(fetchLocations())
      dispatch(loadOrganizers())
      dispatch(fetchEventTypes())
    }

    fetchData().then()
  }, [dispatch])

  const datesValidate = (startDate, endDate, salesEndDate) => {
    let validate = false
    if (moment(salesEndDate).diff(moment(startDate), 'minutes') > 0) {
      showSnackbarError(
        'La Fecha y Hora de corte no pueden ser superior a la Fecha y Hora de Inicio.',
        4000
      )
    } else {
      if (moment().subtract(3, 'hours').isAfter(startDate, 'day')) {
        showSnackbarError(
          'La Fecha de Inicio no puede ser anterior a la Fecha actual.'
        )
      } else {
        if (moment().subtract(3, 'hours').isAfter(startDate)) {
          showSnackbarError(
            'La Hora de Inicio no puede ser anterior a la Hora actual.'
          )
        } else {
          if (moment(startDate).isAfter(endDate, 'day')) {
            showSnackbarError(
              'La Fecha de Fin no puede ser anterior a la Fecha de Inicio.'
            )
          } else {
            if (moment(startDate).isAfter(endDate)) {
              showSnackbarError(
                'La Hora de Fin no puede ser anterior a la Hora de Inicio.'
              )
            } else {
              validate = true
            }
          }
        }
      }
    }
    return validate
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    if (country !== 'ar' && !values.mpAuthorized) {
      setValues(values)
      setShowConfirmationDialog(true)
    } else {
      const locationId = values.LocationId
      const organizerId = values.OrganizerId
      try {
        if (
          datesValidate(
            values?.StartDateTime,
            values?.EndDateTime,
            values?.SalesEndDateTime
          )
        ) {
          setSubmitting(true)
          values.AllowedPaymentTypeId = 0
          values.creditCard &&
            (values.AllowedPaymentTypeId |= PAYMENT_IDS.CREDIT_CARD)
          values.debitCard &&
            (values.AllowedPaymentTypeId |= PAYMENT_IDS.DEBIT_CARD)
          values.ticket && (values.AllowedPaymentTypeId |= PAYMENT_IDS.TICKET)
          values.LocationId = parseInt(values.LocationId[0])
          values.OrganizerId = parseInt(values.OrganizerId[0])
          if (!values.mercadoPago && values.macroClick) {
            values.PaymentGatewayAvailable = ACCOUNTS.MACRO
          }
          if (values.mercadoPago && values.macroClick) {
            values.PaymentGatewayAvailable = ACCOUNTS.BOTH
          }
          const event = await dispatch(create(values))
          onSuccess(event?.Id)
        }
      } catch (error) {
        console.log(error)
        showSnackbarError(error)
      } finally {
        setSubmitting(false)
      }
      values.LocationId = locationId
      values.OrganizerId = organizerId
    }
  }

  const handleSubmitConfirmation = async () => {
    setShowConfirmationDialog(false)
    const locationId = values.LocationId
    const organizerId = values.OrganizerId
    try {
      if (
        datesValidate(
          values?.StartDateTime,
          values?.EndDateTime,
          values?.SalesEndDateTime
        )
      ) {
        values.AllowedPaymentTypeId = 0
        values.creditCard &&
          (values.AllowedPaymentTypeId |= PAYMENT_IDS.CREDIT_CARD)
        values.debitCard &&
          (values.AllowedPaymentTypeId |= PAYMENT_IDS.DEBIT_CARD)
        values.ticket && (values.AllowedPaymentTypeId |= PAYMENT_IDS.TICKET)
        values.LocationId = parseInt(values.LocationId[0])
        values.OrganizerId = parseInt(values.OrganizerId[0])
        const event = await dispatch(create(values))
        onSuccess(event?.Id)
      }
    } catch (error) {
      console.log(error)
      showSnackbarError(error)
    }
    values.LocationId = locationId
    values.OrganizerId = organizerId
  }

  const [values, setValues] = useState({
    Title: '',
    Description: '',
    LocationId: [],
    OrganizerId: [],
    TypeId: 1,
    MaxOnlineTicketSales: 2,
    StartDateTime: moment().set('hour', 23).set('minute', 30),
    EndDateTime: moment().set('hour', 23).set('minute', 59),
    SalesEndDateTime: moment().set('hour', 21).set('minute', 30),
    IsVisibleForOrganizer: true,
    IsHidden: false,
    IsSoldOut: false,
    PayDirectToOrganizer: false,
    IncludeInMercadoPagoAccounting: false,
    creditCard: true,
    debitCard: true,
    ticket: true,
    mpAuthorized: false,
    EventResellerType: '',
    AdmissionAge: 18,
    Billable: true,
    mercadoPago: true,
    macroClick: false,
    PaymentGatewayAvailable: ACCOUNTS.MERCADO_PAGO
  })

  return (
    <PaperContainer title='Creación de evento'>
      {pending || pendingData || pendingOrganizers ? (
        <CircularProgress size={24} color='secondary' />
      ) : (
        <EventForm
          values={values}
          locations={locations}
          organizers={organizers}
          eventTypes={eventTypes}
          actionText='Crear'
          handleSubmit={handleSubmit}
          actionPending={actionPending}
          onCancel={onCancel}
        />
      )}
      <ConfirmationDialog
        open={showConfirmationDialog}
        loading={false}
        handleConfirmation={handleSubmitConfirmation}
        onCancel={() => setShowConfirmationDialog(false)}
        title='IMPORTANTE'
        message={message}
      />
    </PaperContainer>
  )
}
